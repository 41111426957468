@import 'intl-tel-input/build/css/intlTelInput.css';

@font-face {
  font-family: 'Nimbus Sans';
  src: local('Nimbus Sans L Bold'), local('NimbusSanL-Bol'),
    url('../fonts/NimbusSanL-Bol.woff2') format('woff2'),
    url('../fonts/NimbusSanL-Bol.woff') format('woff'),
    url("../fonts/NimbusSanL-Bol.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nimbus Sans';
  src: local('Nimbus Sans L Regular'), local('NimbusSanL-Reg'),
    url('../fonts/NimbusSanL-Reg.woff2') format('woff2'),
    url('../fonts/NimbusSanL-Reg.woff') format('woff'),
    url("../fonts/NimbusSanL-Reg.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'Nimbus Sans', sans-serif;
  overflow-x: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0 0;
}

h2 {
  font-size: 40px;
}

ul {
  padding: 0 0;
  margin: 0 0;
}

li {
  list-style-type: none;
}

address {
  font-style: normal;
}

p {
  line-height: 25px;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #111;
}

button {
  border: none;
  cursor: pointer;
}

textarea {
  resize: none;
  overflow: hidden;
  height: auto;
}

.container {
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.pink_deep {
  color: #8A1455;
  font-weight: bold;
}

.accordion {
  width: 100%;
  -ms-flex-item-align: start;
  align-self: flex-start;
  text-align: start;
  border-top: 1px solid #E4E4E4;
}

.accordion-item {
  padding: 25px 15px 25px 0;
  border-bottom: 1px solid #E4E4E4;
  cursor: pointer;
}

.accordion-item-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 0;
  -webkit-transition: margin-bottom 300ms ease-in-out;
  -o-transition: margin-bottom 300ms ease-in-out;
  transition: margin-bottom 300ms ease-in-out;
}

.accordion-item-title {
  font-size: 17px;
  line-height: 1.3;
}

.accordion-item-description {
  height: 0;
  overflow: hidden;
  opacity: 0;
  -webkit-transition: height 300ms ease-in-out, opacity 300ms ease-in-out;
  -o-transition: height 300ms ease-in-out, opacity 300ms ease-in-out;
  transition: height 300ms ease-in-out, opacity 300ms ease-in-out;
}

.accordion-chevron {
  width: 20px;
  height: 10px;
  -webkit-transition: -webkit-transform 300ms ease-in-out;
  transition: -webkit-transform 300ms ease-in-out;
  -o-transition: transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
}

.active .accordion-chevron {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.active .accordion-item-header {
  margin-bottom: 10px;
}

.active .accordion-item-description {
  opacity: 1;
}

.iti__flag {
  background-image: url("../img/flags/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("../img/flags/flags@2x.png");
  }
}

.iti--allow-dropdown {
  width: 100%;
}

.iti__selected-flag {
  position: absolute;
  top: 28px;
  left: 9px;
  padding: 0;
  height: auto;
  border: none;
  outline-color: rgba(17, 17, 17, 1);
  background-color: transparent;
}

.iti__selected-flag:hover {
  background-color: transparent !important;
}

.iti__arrow {
  height: 8px;
  width: 30px;
  border: none;
  background: transparent url('../img/select-chevron.svg') no-repeat;
}

/* Section Hero */

.hero-block {
  position: relative;
  padding-top: 54px;
  padding-bottom: 215px;
  background: transparent url('../img/hero-bg@2x.jpg') no-repeat;
  background-position: center;
  background-size: cover;
}

.hero-block__top {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[3];
  grid-template-columns: repeat(3, 1fr);
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 219px
}

.hero-block__top-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.hero-block__log-in-btn {
  padding: 17px 31px;
  color: #111;
  background-color: white;
  font-weight: bold;
  border: 1px solid #111111;
  border-radius: 5px;
}

.hero-block__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.hero-block__title {
  max-width: 654px;
  margin-bottom: 42px;
  text-align: center;
  font-size: 50px;
}

.hero-block__description {
  max-width: 600px;
  margin-bottom: 42px;
  text-align: center;
  font-weight: bold;
  font-size: 22px;
}

.hero-block__stores-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 27px;
}

/* Section About app */

.about-app {
  padding-top: 100px;
  padding-bottom: 100px;
}

.about-app__title {
  margin-bottom: 44px;
  text-align: center;
}

.about-app__features {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about-app__feature {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 80px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.first-feature {
  margin-bottom: 66px;
}

.about-app__feature-text {
  max-width: 400px;
}

.about-app__feature-title {
  margin-bottom: 15px;
  font-size: 25px;
  line-height: 35px;
}

.about-app__feature-description {
  line-height: 25px;
}

/* Section Clients */

.reviews {
  padding-bottom: 100px;
}

.reviews__title {
  margin-bottom: 60px;
  text-align: center;
}

.reviews__cards {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 34px 1fr 34px 1fr;
  grid-template-columns: repeat(3, 1fr);
  gap: 34px;
  overflow-y: auto;
}

.reviews__card {
  padding: 20px;
  border-radius: 10px;
  background-color: #f6f6f6;
}

.reviews__card-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 14px;
}

.reviews__card-avatar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 59px;
  height: 59px;
  color: white;
  border-radius: 100%;
}

.reviews__card-avatar.first-reviewer {
  background-color: #B04758;
}

.reviews__card-avatar.second-reviewer {
  background-color: #9E3A71;
}

.reviews__card-avatar.third-reviewer {
  background-color: #9C2056;
}

.reviews__card-rate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 4px;
  margin-bottom: 18px;
}

.reviews__card-description {
  min-width: 266px;
}

/* Section Business list */

.business-list {
  padding-top: 55px;
  padding-bottom: 55px;
  background-color: #2B2B2B;
  color: white;
}

.business-list__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.business-list__content-left {
  max-width: 500px;
}

.business-list__title {
  margin-bottom: 20px;
}

.business-list__description {
  margin-bottom: 16px;
}

.business-list__point {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 7px;
  margin-bottom: 24px;
}

.business-list__point:last-child {
  margin-bottom: 20px;
}

.business-list__point-text {
  -webkit-transform: translateY(4px);
  -ms-transform: translateY(4px);
  transform: translateY(4px);
}

.business-list__btn {
  display: block;
  padding: 15px 23px;
  border-radius: 5px;
  color: #111;
  text-align: center;
  font-weight: bold;
  background-color: white;
}

/* Section Our story */

.our-story.container {
  max-width: 600px;
}

.our-story {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

.our-story__logo {
  width: 128px;
  height: 125px;
}

.our-story__title {
  margin-bottom: 23px;
}

.our-story__secondary-title {
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin-bottom: 23px;
  text-align: start;
  font-weight: normal;
  font-size: 22px;
  line-height: 1.4;
}

.our-story__secondary-title.second {
  margin-bottom: 20px;
}

.our-story__description {
  -ms-flex-item-align: start;
  align-self: flex-start;
  text-align: start;
}

.our-story__description.second {
  margin-bottom: 30px;
}

.our-story__btn {
  display: block;
  padding: 15px 40px;
  margin-bottom: 100px;
  border-radius: 5px;
  background-color: #111;
  text-align: center;
  color: white;
  font-weight: bold;
}

/* Footer */

footer {
  padding-top: 63px;
  padding-bottom: 59px;
  background-color: #F6F6F6;
}

.footer__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 30px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footer__list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 30px;
  max-width: 250px;
}

.footer__logo {
  margin-bottom: 15px;
}

.footer__list-item .bold {
  font-weight: bold;
}

.footer__list-item .social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 7px;
  font-weight: bold;
}

.footer__list-item .social-img {
  -webkit-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  transform: translateY(-2px);
}

.footer__list-item:last-child {
  gap: 18px;
}

.footer__copyright-desktop {
  display: block;
}

.footer__copyright-mobile {
  display: none;
}

.footer__list-item address {
  line-height: 25px;
}

// Modal

.modal {
  display: none;
}

.modal__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(43, 43, 43, 0.8);
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  overflow: auto;
}

.modal-active .modal__overlay {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}

.modal__overlay-content {
  position: relative;
  padding: 52px 100px 41px 100px;
  border-radius: 10px;
  background-color: white;
  color: #111;
}

.log-in-modal__overlay-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 55px 120px;
}

.partner-modal__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 30px;
}

.modal__close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: transparent;
}

.modal__title {
  font-size: 20px;
  margin-bottom: 30px;
}

.log-in-modal__title {
  margin-bottom: 26px;
}

.log-in-modal__form_description {
  margin-bottom: 12px;
}

.modal__input {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}

.log-in-modal__input {
  min-width: 260px;
  margin-bottom: 30px;
}

.modal__input-label {
  position: absolute;
  top: 17px;
  left: 9px;
  color: #818181;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: text;
}

.modal__phone-input-label {
  position: absolute;
  top: 7px;
  left: 9px;
  color: #818181;
  font-size: 14px;
  cursor: text;
}

.modal__input-label-active {
  top: 7px;
  left: 9px;
  font-size: 14px;
}

.modal__input-field {
  width: 100%;
  padding: 25px 9px 7px 9px;
  border: 1px solid rgba(221, 221, 221, 1);
  border-radius: 4px;
  outline: none;
}

.modal__input-field::placeholder {
  font-size: 14px;
  color: #C4C4C4;
}

.modal__phone-input-field {
  padding-left: 60px !important;
}

.modal__input-field:focus {
  border: 2px solid rgba(17, 17, 17, 1);
  margin: -1px !important;
}

.modal__input-field:focus+.modal__input-label {
  top: 7px;
  font-size: 14px;
}

.partner-modal__submit-btn {
  color: white;
  background-color: #000000;
  border-radius: 5px;
  padding: 18px 41px;
  font-weight: bold;
}

.partner-modal__log-in-btn {
  background-color: transparent;
}

.log-in-modal__submit-btn {
  color: white;
  background-color: #000000;
  border-radius: 5px;
  padding: 18px 61px;
  font-weight: bold;
}

.log-in-modal__submit-bottom-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 26px;
}

.log-in-modal__submit-btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 26px;
}

.log-in-modal__secondary-btn {
  padding: 17px 31px;
  color: #111;
  background-color: white;
  font-weight: bold;
  border: 1px solid #111111;
  border-radius: 5px;
}

.contact-modal__title {
  font-size: 30px;
}

.contact-modal__overlay-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
          justify-content: center !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.form-field-error {
  position: absolute;
  bottom: -20px;
  left: 5px;
  color: #FF3300;
}

@media (max-width: 1100px) {
  .hero-block__top {
    margin-bottom: 60px;
  }

  .about-app__feature {
    gap: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .about-app__feature.second-feature {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .about-app__feature-text {
    max-width: 600px;
  }

  .about-app__feature.first-feature {
    margin-bottom: 40px;
  }

  .about-app__feature-title {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.5;
  }

  .about-app__feature-description {
    font-size: 16px;
    line-height: 1.5;
  }

  .reviews__cards {
    -ms-grid-columns: none;
    grid-template-columns: none;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 976px) {
  .business-list__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .business-list {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .business-list__img {
    margin-bottom: 31px;
  }
}

@media (max-width: 768px) {
  .hero-block {
    padding-top: 30px;
    padding-bottom: 300px;
    background-image: url('../img/hero-bg-mobile@2x.jpg');
  }

  .hero-block__top {
    margin-bottom: 40px;
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr);
  }

  .hero-block__title {
    margin-bottom: 10px;
    font-size: 34px;
  }

  .hero-block__log-in-btn {
    border: 0;
    padding: 12px 16px;
  }

  .hero-block__top-item.first {
    display: none;
  }

  .hero-block__top-item.second {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
  }

  .hero-block__top-item.third {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
  }

  .hero-block__description {
    margin-bottom: 35px;
    font-size: 16px;
  }

  .hero-block__stores-wrapper {
    margin-bottom: 30px;
  }

  .reviews__cards {
    display: -ms-grid;
    display: grid;
    padding-left: 20px;
    padding-right: 20px;
    max-width: none;
    -ms-grid-columns: 1fr 34px 1fr 34px 1fr;
    grid-template-columns: repeat(3, 1fr);
    gap: 34px;
    overflow-y: auto;
  }

  .reviews.container {
    padding: 0 0 70px 0;
  }

  footer {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .footer__logo {
    margin-bottom: 23px;
  }

  .footer__copyright-desktop {
    display: none;
  }

  .footer__copyright-mobile {
    display: block;
  }

  .footer__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .modal__overlay-content {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 480px) {

  h2 {
    font-size: 30px;
  }

  .accordion-item-title {
    font-size: 16px;
  }

  /* Section About app */

  .about-app {
    padding-top: 60px;
    padding-bottom: 70px;
  }

  .about-app__title {
    margin-bottom: 30px;
  }

  .about-app__feature-img {
    margin-bottom: 13px;
  }

  /* Section Reviews */

  .reviews__title {
    margin-bottom: 30px;
  }

  .reviews__card-avatar {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .reviews__cards {
    gap: 7px;
  }

  /* Section Business list */

  .business-list__point:last-child {
    margin-bottom: 30px;
  }

  /* Section Our story */

  .our-story {
    padding-top: 50px;
    padding-bottom: 70px;
  }

  .our-story__secondary-title {
    margin-bottom: 15px;
    font-size: 20px;
  }

  .our-story__description.second {
    margin-bottom: 20px;
  }

  .our-story__btn {
    margin-bottom: 83px;
  }

  .modal__overlay-content {
    width: calc(100% - 40px);
    height: 100%;
    border-radius: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 78px;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .log-in-modal__form {
    width: 100%;
  }

  .log-in-modal__submit-btn {
    width: 100%;
  }

  .log-in-modal__submit-bottom-wrapper {
    width: 100%;
  }

  .log-in-modal__secondary-btn {
    width: 100%;
  }

  .partner-modal__submit-btn-wrapper {
    width: 100%;
  }

  .partner-modal__submit-btn {
    width: 100%;
  }

  .modal__close-btn {
    right: 20px;
    top: 35px;
  }
}

// Terms and conditions

.terms-and-conditions {
  margin: 0 auto;
  max-width: 600px;
  padding: 90px 20px;

  p {
    margin-bottom: 20px;
  }

  h2 {
    margin-bottom: 30px;
  }

  ol {
    margin-top: 10px;
    margin-left: 20px;
    list-style-type: upper-alpha;

    ol {
      list-style-type: lower-alpha;
    }
  }

  li {
    margin-bottom: 20px;
    list-style-type: unset;
  }

  ul {
    padding: 0 0;
    margin: 0 0;
  }
}

.navbar {
  background-color: #f9f9f9;
  border-bottom: 1px solid #cbcbcb;
  height: 60px;
  padding: 7px 35px;
  position: absolute;
  width: 100%;

  &__logo {
    height: 50px;
    padding: 2px 0;
  }

  &__inner {
    display: table;
    height: 100%;
    width: 100%;
  }

  &__logo-wrapper {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
  }

  &__tabs {
    display: table-cell;
  }

  &__actions {
    display: table-cell;
    height: 100%;
    position: relative;
    width: 300px;
  }
}

.page-not-found {
  width: 100%;

  &__container {
    align-items: center;
    background: url('../img/coming-soon-bg.jpg');
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    width: 100%;
  }

  &__main {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5rem;
  }

  &__logo {
    margin-bottom: 1.8rem;
    text-align: center;

    .svg-image {
      height: 10rem;
      width: 30rem;
    }
  }

  &__text {
    color: #333;;
    font-size: 3rem;
  }

  &__button {
    .custom_button {
      height: 3rem;
      margin-bottom: 3rem;
      padding: 0 3.8rem;
    }
  }

  &__contact {
    font-size: 1.1rem;

    &-button {
      background-color: transparent;
      border: 0;
      color: #9e1e62;
      cursor: pointer;
      margin-left: .5rem;
      padding: 0;
      text-decoration: none;
    }
  }
}